// ReadyToRevolutionizeSection.js
import React from 'react';
import VideoGallery from '../VideoGallery';

const ReadyToRevolutionizeSection = () => (
  <section className="container" id="ready">
    <h3 id="readyToRevo">
      Ready to revolutionize
      <br />your digital presence?
    </h3>

    <section className="videoGallerySection">
      <VideoGallery />          
    </section>
  
    <h3 id="dontJustCreate">
      <em>
        Don't just create experiences captivate your audience with our unique new physical reality.
      </em>
    </h3>
  </section>
);

export default ReadyToRevolutionizeSection;
