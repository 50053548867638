import React, { useRef } from "react";
import { SmoothScrollbar } from "@14islands/r3f-scroll-rig";
import { Loader } from "@react-three/drei";
import "@14islands/r3f-scroll-rig/css";
import { useAppState } from "./Sections/useAppState";
import { useAppEffects } from "./Sections/useAppEffects";

// ------------------------------------------ Sections --------------------------------------------- //

import CodropsFrame from "./CodropsFrame";
import ClearStorageAndCache from "./ClearStorageAndCache";
import Header from "./Sections/HeaderSection";
import HeadAppSection from "./Sections/HeadAppSection";
import TitleSection from "./Sections/TitleSection";
import DifferentByDesignSection from "./Sections/DifferentByDesignSection";
import BoySectionContainer from "./Sections/BoySectionContainer";
import ReadyToRevolutionizeSection from "./Sections/ReadyToRevolutionizeSection";
import SvgAnimationsSection from "./Sections/SVGAnimationsSection";
import CloudSectionContainer from "./Sections/CloudSectionContainer";
import WarningMessage from "./Sections/WarningMessage";

// -------------------------------------------------------------------------------------------------- //


export default function App() {
  const {
    eventSource,
    fluidCanvasRef,
    displacementCanvasRef,
    enabled,
    setEnabled,
    targetRef,
    cloudSectionRef,
    isAutoScrolling,
    setIsAutoScrolling,
    scrollEventEnabled,
    setScrollEventEnabled,
    smoothScrollbarEnabled,
    setSmoothScrollbarEnabled,
    initializeFluidAndDisplacement
  } = useAppState();

  const smoothScrollToBottom = (duration) => {
    setSmoothScrollbarEnabled(false);
    setIsAutoScrolling(true);
    setScrollEventEnabled(false);

    const start = window.pageYOffset || document.documentElement.scrollTop;
    const startTime = performance.now();

    const animateScroll = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeInOutCubic = progress < 0.5
        ? 4 * progress * progress * progress
        : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      const windowHeight = window.innerHeight;
      const target = documentHeight - windowHeight;
      const change = target - start;

      window.scrollTo(0, start + change * easeInOutCubic);

      if (progress < 1 && window.pageYOffset < target) {
        requestAnimationFrame(animateScroll);
      } else {
        setIsAutoScrolling(false);
        setScrollEventEnabled(true);
        setSmoothScrollbarEnabled(true);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  useAppEffects(initializeFluidAndDisplacement, targetRef, isAutoScrolling, scrollEventEnabled, smoothScrollToBottom, setScrollEventEnabled, setSmoothScrollbarEnabled, setIsAutoScrolling);

  return (
    <>
      <ClearStorageAndCache />

      <div ref={eventSource}>
        <div className="gradientBackground"></div>
        <div className="BlackgradientBackground"></div>
        <CodropsFrame />

        {smoothScrollbarEnabled && (
          <SmoothScrollbar
            enabled={enabled}
            config={{ syncTouch: true }}
          />
        )}

        <article>
          <Header />
          <HeadAppSection />
          <TitleSection />
          <DifferentByDesignSection />
          <BoySectionContainer />
          <ReadyToRevolutionizeSection />
          <h3 id="target2" className="heading2" ref={targetRef}>
            <span className="font-10 font-upper"> Immersive Web Solutions Suite ↓</span>
          </h3>
          <SvgAnimationsSection />
          <CloudSectionContainer cloudSectionRef={cloudSectionRef} />
          <WarningMessage />
        </article>

        <Loader
          containerStyles={{
            background: "#ecf3f6",
            top: "auto",
            bottom: 0,
            height: "100%",
            width: "100%",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center"
          }}
          innerStyles={{ background: "transparent", width: "40vh", height: "105px" }}
          barStyles={{ background: 'linear-gradient(to right bottom, #ff4d4d, #fed1c7)', height: "30%", borderRadius: "100px" }}
          dataStyles={{ color: 'ff4d4d', fontSize: '14px', fontFamily: "Poppins, sans-serif" }}
          dataInterpolation={(p) => `Try to Scroll and Drag ${p.toFixed(2)}%`}
          initialState={(active) => active}
        />
      </div>
      
      <canvas id="fluid" ref={fluidCanvasRef}></canvas>
    </>
  );
}
