import * as React from 'react';
import { FaBars } from "react-icons/fa6";
import { isMobile } from "react-device-detect";


function MoveToSlides() {
  location.href = 'https://memotics.io/Slides/';
}

function MoveToAboutUs() {
  location.href = 'https://memotics.io/AboutUs/';
}

function MoveToMDS() {
  location.href = 'https://memotics.io/MDS/'
}

function MoveToTour() {
  location.href = 'https://memotics.io/Tour/';
}

function MoveToWP() {
  location.href = 'https://memotics.io/WhitePaper/';
}

// function MoveToTour() {
//   location.href = 'https://memotics.io/Tour/';
// }



const Dropdown = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div class="dropdown">
      <button className='dropbtn' onClick={handleOpen}>Menu  <i class="fa fa-bars"></i></button>
      {open ? (
        
        <ul className="menu">

          <li className="menu-item">
            <button  onClick={MoveToAboutUs}>About Us</button>
          </li>
          {/* <li className="menu-item">
            <button onClick={MoveToSlides}>Pitch</button>
          </li> */}
          <li className="menu-item">
            <button onClick={MoveToMDS}>MDS</button>
          </li>
          {/* <li className="menu-item">
            <button  onClick={MoveToWP}>WhitePaper</button>
          </li> */}
        </ul>
      ) : null}
      
    </div>
  );
};

export default Dropdown;