import React, { useRef, useState, useEffect } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import * as THREE from 'three';

const PausePlayButton = ({ video, positionOffset = [0, 1, 0], imgPathPlay = "", imgPathPause = "", scale = [1, 1, 1] }) => {
    const texturePlay = useLoader(THREE.TextureLoader, imgPathPlay);
    const texturePause = useLoader(THREE.TextureLoader, imgPathPause);
    const buttonRef = useRef();
    const [isPlaying, setIsPlaying] = useState(video && !video.paused);
    const [aspectRatio, setAspectRatio] = useState(1);

    useEffect(() => {
        const updateAspectRatio = (texture) => {
            if (texture.image) {
                setAspectRatio(texture.image.width / texture.image.height);
            }
        };

        updateAspectRatio(isPlaying ? texturePause : texturePlay);
    }, [isPlaying, texturePlay, texturePause]);

    const handleClick = (e) => {
        e.stopPropagation();
        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        if (!video) {
            console.warn("Video element is not defined");
        } else {
            const updateState = () => {
                setIsPlaying(!video.paused);
            };

            video.addEventListener('play', updateState);
            video.addEventListener('pause', updateState);

            return () => {
                video.removeEventListener('play', updateState);
                video.removeEventListener('pause', updateState);
            };
        }
    }, [video]);

    useFrame(({ camera }) => {
        if (buttonRef.current && camera) {
            buttonRef.current.lookAt(camera.position);
        }
    });

    useEffect(() => {
        const updateAspectRatioOnLoad = (texture) => {
            texture.image.addEventListener('load', () => {
                setAspectRatio(texture.image.width / texture.image.height);
            });
        };

        updateAspectRatioOnLoad(texturePlay);
        updateAspectRatioOnLoad(texturePause);
    }, [texturePlay, texturePause]);

    return (
        <mesh
            ref={buttonRef}
            position={positionOffset}
            onClick={handleClick}
            scale={scale}
        >
            <planeGeometry args={[aspectRatio, 1]} />
            <meshBasicMaterial
                map={isPlaying ? texturePause : texturePlay}
                transparent={true}
            />
        </mesh>
    );
}

export default PausePlayButton;
