
import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
const AeroplaneModel = (props) => {

  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/Models/Aeroplane_animation.glb')
  const { actions, ref, names } = useAnimations(animations, group)


  function playAnims(actionArray) {
    actions[actionArray].timeScale = 1.2;
    actions[actionArray].reset().fadeIn(0.5).play();
  }

  useEffect(() => {
    names.forEach(playAnims)
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
    <group name="Scene">
      <group name="Empty" rotation={[Math.PI / 2, 0, 0]} />
      <group name="Empty002" position={[0, -0.204, 0]} />
      <group
        name="Empty001"
        position={[0.004, 0.031, -0.159]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <group name="BezierCircle">
        <group
          name="Plane_main_body"
          position={[0, 0, 400]}
          rotation={[-1.14, 0.109, 1.62]}
          scale={14.169}>
          <mesh
            name="Circle"
            castShadow
            receiveShadow
            geometry={nodes.Circle.geometry}
            material={materials.MainBody}
          />
          <mesh
            name="Circle_1"
            castShadow
            receiveShadow
            geometry={nodes.Circle_1.geometry}
            material={materials.wingsBeam}
          />
          <mesh
            name="Circle_2"
            castShadow
            receiveShadow
            geometry={nodes.Circle_2.geometry}
            material={materials.Metal}
          />
          <mesh
            name="Circle_3"
            castShadow
            receiveShadow
            geometry={nodes.Circle_3.geometry}
            material={materials.Material}
          />
          <mesh
            name="Circle_4"
            castShadow
            receiveShadow
            geometry={nodes.Circle_4.geometry}
            material={materials['Material.004']}
          />
          <mesh
            name="Circle_5"
            castShadow
            receiveShadow
            geometry={nodes.Circle_5.geometry}
            material={materials['Material.005']}
          />
          <mesh
            name="Circle_6"
            castShadow
            receiveShadow
            geometry={nodes.Circle_6.geometry}
            material={materials['Material.003']}
          />
          <mesh
            name="Circle001"
            castShadow
            receiveShadow
            geometry={nodes.Circle001.geometry}
            material={materials.Metal}
            position={[-0.013, -1.396, 0.066]}
            scale={0.88}
          />
          <group
            name="Circle002"
            position={[-0.016, -1.618, 0.088]}
            rotation={[-Math.PI, 0, 0]}
            scale={0.22}>
            <mesh
              name="Circle004"
              castShadow
              receiveShadow
              geometry={nodes.Circle004.geometry}
              material={materials['Material.001']}
            />
            <mesh
              name="Circle004_1"
              castShadow
              receiveShadow
              geometry={nodes.Circle004_1.geometry}
              material={materials.Metal}
            />
            <mesh
              name="pCube1_Blur_effect_0"
              castShadow
              receiveShadow
              geometry={nodes.pCube1_Blur_effect_0.geometry}
              material={materials.Blur_effect}
              position={[0.001, 0.524, -0.011]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={9.116}
            />
          </group>
          <mesh
            name="Circle006"
            castShadow
            receiveShadow
            geometry={nodes.Circle006.geometry}
            material={materials.Metal}
            position={[-0.001, 1.38, 0.435]}
            rotation={[-Math.PI, 0, 0]}
            scale={0.217}
          />
          <mesh
            name="Circle009"
            castShadow
            receiveShadow
            geometry={nodes.Circle009.geometry}
            material={materials['Material.001']}
            position={[-0.004, -1.451, 0.084]}
            rotation={[-Math.PI, 0, 0]}
            scale={0.231}
          />
          <mesh
            name="Circle010"
            castShadow
            receiveShadow
            geometry={nodes.Circle010.geometry}
            material={materials['Material.001']}
            position={[-0.004, -1.399, 0.084]}
            rotation={[-Math.PI, 0, 0]}
            scale={0.231}
          />
          <mesh
            name="Cylinder002"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder002.geometry}
            material={materials['Material.001']}
            position={[-0.021, -1.205, 0.082]}
            rotation={[1.371, 0.008, 1.327]}
            scale={0.091}
          />
        </group>
      </group>
    </group>
  </group>
)
}
export default AeroplaneModel;
  useGLTF.preload('/Models/Aeroplane_animation.glb')
  
