// ClearStorageAndCache.js
import React, { useEffect } from "react";

const ClearStorageAndCache = () => {
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => {
        indexedDB.deleteDatabase(db.name);
      });
    });
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, []);
  return null;
};

export default ClearStorageAndCache;
