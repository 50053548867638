import React, { useState, useRef, useEffect } from 'react';

const AnimatedButton = () => {
  const [isPlaying, setIsPlaying] = useState(false); // Start with not playing
  const audioRef = useRef(null);

  const toggleMusic = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className={`playButton ${isPlaying ? 'playing' : ''}`} onClick={toggleMusic}>
      {isPlaying ? (
        <svg viewBox="0 0 100 100" className="icon pause-icon">
          <rect x="35" y="30" width="10" height="40" fill="white" />
          <rect x="55" y="30" width="10" height="40" fill="white" />
        </svg>
      ) : (
        <svg viewBox="0 0 100 100" className="icon play-icon">
          <polygon points="35,25 70,50 35,75" fill="white" />
        </svg>
      )}
      <audio ref={audioRef} loop>
        <source src="/audio/bgm.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AnimatedButton;
