// SvgAnimationsSection.js
import React, { Suspense, lazy } from 'react';

const SvgAnimation1 = lazy(() => import('../SvgAnimation1'));
const SvgAnimation2 = lazy(() => import('../SvgAnimation2'));

const SvgAnimationsSection = () => (
  <>
    <Suspense fallback=''>
      <SvgAnimation1 />
    </Suspense>

    <Suspense fallback=''>
      <SvgAnimation2 />
    </Suspense>
  </>
);

export default SvgAnimationsSection;
