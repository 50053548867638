// BoySectionContainer.js
import React from 'react';
import BoySection from '../BoySection';

const BoySectionContainer = () => (
  <div id='BoySection'>
    <BoySection />
  </div>
);

export default BoySectionContainer;
