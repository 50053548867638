// useAppState.js
import { useState, useRef, useCallback } from "react";
import { initFluid } from '../CursorFluid';

export const useAppState = () => {
  const eventSource = useRef();
  const fluidCanvasRef = useRef(null);
  const displacementCanvasRef = useRef(null);
  const [enabled, setEnabled] = useState(true);
  const targetRef = useRef(null);
  const cloudSectionRef = useRef(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const [scrollEventEnabled, setScrollEventEnabled] = useState(true);
  const [smoothScrollbarEnabled, setSmoothScrollbarEnabled] = useState(true);

  const initializeFluidAndDisplacement = useCallback(() => {
    if (fluidCanvasRef.current) {
      initFluid(fluidCanvasRef.current);
    }
  }, []);

  return {
    eventSource,
    fluidCanvasRef,
    displacementCanvasRef,
    enabled,
    setEnabled,
    targetRef,
    cloudSectionRef,
    isAutoScrolling,
    setIsAutoScrolling,
    scrollEventEnabled,
    setScrollEventEnabled,
    smoothScrollbarEnabled,
    setSmoothScrollbarEnabled,
    initializeFluidAndDisplacement
  };
};
