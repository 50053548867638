import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'


const OrgModel4 = (props) => {
    const { nodes, materials } = useGLTF('/Models/Organic4.glb')
    
    return (
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3.geometry}
          material={materials['default.002']}
          position={[2.152, 0, 0.475]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.008}
        />
      </group>
    )
  }

  export default OrgModel4
  useGLTF.preload('/Models/Organic4.glb')