// Header.js
import React from 'react';

const Header = () => (
  <header className="container">
    <div className="headerLayout">
      <h2 id="headerh2">
        We architect world class solutions to weave your brand story with immersive Web Magic.
      </h2>
    </div>
  </header>
);

export default Header;
