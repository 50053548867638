import React, { useRef, useState } from 'react';

const videos = [
    { id: 1, src: '/videos/OCBC_Compiled_ShortenClip.webm', preload: 'metadata', title: 'OCBC Decentraland', hoverTitle: 'Experience Future of Web3 Today', },
    { id: 2, src: '/videos/HCWasteManagement_Compiled_Shorten.webm', preload: 'metadata', title: 'HCI Waste Management', hoverTitle: 'Sorting Out a Sustainable Tomorrow', },
    { id: 3, src: '/videos/SDW_Compilation_Shorten.webm', preload: 'metadata', title: 'Singapore Design Week', hoverTitle: 'Where Virtual Worlds Come Alive', },
    { id: 4, src: '/videos/Final_Edit_Motach.webm', preload: 'metadata', title: 'MOTAC', hoverTitle: 'Where Reality Meets Endless Possibilities', },
    { id: 5, src: '/videos/Shinee_Compiled_Shorten.webm', preload: 'metadata', title: 'Personal Branding', hoverTitle: 'Bringing Your Vision to Life', },
    { id: 6, src: '/videos/Wander_Compiled_Shorten.webm', preload: 'metadata', title: 'Unlocking Creative Currency', hoverTitle: 'The Art of Motion in Every Second', },
    { id: 7, src: '/videos/World_Cup_FINAL_VIDEO_Compressed.webm', preload: 'metadata', title: 'Homie: World Cup', hoverTitle: 'Feel the Thrill in Three Dimensions', },
    { id: 8, src: '/videos/Homie_Compiled_Shorten.webm', preload: 'metadata', title: 'Memotics: Homie', hoverTitle: 'Endless Motion, Mesmerizing Beauty', },
];

const VideoGallery = () => {
    const [visibleVideos, setVisibleVideos] = useState(4); // Start by showing 4 videos
    const [showLess, setShowLess] = useState(false); // State to track whether "Show Less" button should be shown

    const handleShowMore = () => {
        setVisibleVideos(prevVisibleVideos => prevVisibleVideos + 4); // Show 4 more videos when button is clicked
        setShowLess(true); // Show "Show Less" button when more videos are shown
    };

    const handleShowLess = () => {
        setVisibleVideos(4); // Show only 4 videos when "Show Less" button is clicked
        setShowLess(false); // Hide "Show Less" button
    };

    return (
        <div className="VideoGallery">
            {videos.slice(0, visibleVideos).map((video) => (
                <VideoCard key={video.id} video={video} />
            ))}
            {showLess ? (
                <button onClick={handleShowLess} className="show-more-button">Show Less Videos</button>
            ) : (
                <button onClick={handleShowMore} className="show-more-button">Show More Videos</button>
            )}
        </div>
    );
};

const VideoCard = ({ video }) => {
    const videoRef = useRef(null);

    const handleMouseEnter = () => {
        if (videoRef.current && videoRef.current.paused) {
            videoRef.current.play().catch((error) => {
                console.error('Error playing video:', error);
            });
        }
    };

    const handleMouseLeave = () => {
        if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.pause();
        }
    };

    return (
        <div className="video-card">
            <div className="video-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <video
                    ref={videoRef}
                    src={video.src}
                    muted
                    loop
                    playsInline
                    preload="auto"
                    className="video-element"
                />
            </div>
            <h3>
                <span className="title-default">{video.title}</span>
                <span className="title-hover">{video.hoverTitle}</span>
            </h3>
            <p>{video.description}</p>
        </div>
    );
};

export default VideoGallery;




// ---------------Pause&Play-------------------
//   const handlePlayPause = () => {
//     if (videoRef.current.paused) {
//       videoRef.current.play();
//       setIsPlaying(true);
//     } else {
//       videoRef.current.pause();
//       setIsPlaying(false);
//     }
//   };

 
//       {/* <div className="video-wrapper">
//         <video
//           ref={videoRef}
//           src={video.src}
//           poster={video.poster}
//           muted
//           loop
//           playsInline
//           className="video-element"
//         />
//         <button onClick={handlePlayPause} className="play-pause-button">
//           {isPlaying ? 'Pause' : 'Play'}
//         </button>
//       </div>
//       <h3>
//         <span className="title-default">{video.title}</span>
//         <span className="title-hover">{video.hoverTitle}</span>
//       </h3> */}
//       <p>{video.description}</p>
//     </div>
//   );
// };

// export default VideoGallery;



// -------------------Autoplay-----------------------
// const VideoGallery = () => {
//     return (
//         <div className="VideoGallery">
//             {videos.map(video => (
//                 <div className="video-card" key={video.id}>
//                     <div className="video-wrapper">
//                         <video 
//                             src={video.src} 
//                             autoPlay 
//                             muted 
//                             loop 
//                             playsInline
//                         ></video>
//                     </div>
//                     <h3>
//                         <span className="title-default">{video.title}</span>
//                         <span className="title-hover">{video.hoverTitle}</span>
//                     </h3>
//                     <p>{video.description}</p>
//                 </div>
//             ))}
//         </div>
//     );
// };

// export default VideoGallery;
