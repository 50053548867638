import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMouseMove = () => {
    setShowControls(true);
    clearTimeout(window.hideControlsTimeout);
    window.hideControlsTimeout = setTimeout(() => {
      setShowControls(false);
    }, 2000);
  };

  useEffect(() => {
    const handleLoadedMetadata = () => {
      const frameNumber = 1; // Example frame number
      const frameRate = 30; // Assuming the video frame rate is 30fps
      const seekTime = frameNumber / frameRate;
      videoRef.current.currentTime = seekTime;
      videoRef.current.pause();
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(window.hideControlsTimeout);
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, []);

  return (
    <div className="video-container" onMouseMove={handleMouseMove}>
      <video
        ref={videoRef}
        width="800"
        crossOrigin="Anonymous"
        disablePictureInPicture
        loop
        muted
        playsInline
        preload="metadata"
        className="video"
      >
        <source src="/videos/sun_ball.mp4" type="video/mp4" />
      </video>
      {showControls && (
        <button onClick={togglePlayPause} className="video-container__play-pause-button">
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button>
      )}
    </div>
  );
};

export default VideoPlayer;

