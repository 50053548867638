import React, { useRef } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import * as THREE from 'three';

const VirtualButton = ({ imagePosition = [0, 0, 0], imgPath = "", scale = [5, 5, 5], newSiteLink = "" }) => {
    const texture = useLoader(THREE.TextureLoader, imgPath);
    const buttonRef = useRef();

    const handleClick = () => {
        if (newSiteLink) {
            window.location.href = newSiteLink;
        }
    };

    useFrame(({ camera }) => {
        if (buttonRef.current) {
            buttonRef.current.lookAt(camera.position);
        }
    });

    return (
        <mesh 
            ref={buttonRef}
            position={imagePosition}
            onClick={handleClick}
            scale={scale}
        >
            <planeGeometry args={[1, 1]} />
            <meshBasicMaterial 
                map={texture}
                transparent={true} // Ensures the material supports transparency
            />
        </mesh>
    );
}

export default VirtualButton;
