// useAppEffects.js
import { useEffect } from "react";

export const useAppEffects = (initializeFluidAndDisplacement, targetRef, isAutoScrolling, scrollEventEnabled, smoothScrollToBottom, setScrollEventEnabled, setSmoothScrollbarEnabled, setIsAutoScrolling) => {
  useEffect(() => {
    initializeFluidAndDisplacement();
  }, [initializeFluidAndDisplacement]);

  useEffect(() => {
    const handleWheel = (event) => {
      if (!scrollEventEnabled) return;

      const targetElement = targetRef.current;
      if (!targetElement) return;

      const rect = targetElement.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isInView && !isAutoScrolling) {
        if (event.deltaY > 0) {
          smoothScrollToBottom(1000);
        }
      }
    };

    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [isAutoScrolling, scrollEventEnabled]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash === '#CloudSection') {
        smoothScrollToBottom(1000);
      }
    };

    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);
};
