// WarningMessage.js
import React from 'react';

const WarningMessage = () => (
  <div id="warning-message" className="rotate">
    <img src="images/rotate.webp" width="100%" alt="Rotate your device" />
  </div>
);

export default WarningMessage;
