import React, { useMemo, useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import PausePlayButton from './PausePlayButton';
import url from './video2.mp4';
import { extend, useFrame } from '@react-three/fiber';

extend(THREE);

const CustomRoundedPlane = ({ width, height, radius, ...props }) => {
  const videoRef = useRef(null);
  const videoTextureRef = useRef();

  const [video] = useState(() => {
    const vid = document.createElement('video');
    vid.preload = 'metadata';
    vid.src = url;
    vid.playsInline = true;
    vid.crossOrigin = 'Anonymous';
    vid.loop = true;
    vid.muted = true;
    vid.className = 'lazy';
    videoRef.current = vid;
    return vid;
  });

  useEffect(() => {
    const handleLoadedMetadata = () => {
      const frameNumber = 880;
      const frameRate = 30;
      const seekTime = frameNumber / frameRate;
      videoRef.current.currentTime = seekTime;
      videoRef.current.pause();
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [videoRef]);

  const handlePlayVideo = () => {
    videoRef.current.play().catch(error => {
      console.error('Error attempting to play the video:', error);
    });
  };

  // Update video texture
  useFrame(() => {
    if (videoTextureRef.current) {
      videoTextureRef.current.needsUpdate = true;
    }
  });

  const createRoundedPlaneGeometry = () => {
    const shape = new THREE.Shape();
    shape.moveTo(-width / 2 + radius, -height / 2);
    shape.lineTo(width / 2 - radius, -height / 2);
    shape.quadraticCurveTo(width / 2, -height / 2, width / 2, -height / 2 + radius);
    shape.lineTo(width / 2, height / 2 - radius);
    shape.quadraticCurveTo(width / 2, height / 2, width / 2 - radius, height / 2);
    shape.lineTo(-width / 2 + radius, height / 2);
    shape.quadraticCurveTo(-width / 2, height / 2, -width / 2, height / 2 - radius);
    shape.lineTo(-width / 2, -height / 2 + radius);
    shape.quadraticCurveTo(-width / 2, -height / 2, -width / 2 + radius, -height / 2);

    const geometry = new THREE.ShapeGeometry(shape);

    // Set UV mapping
    geometry.computeBoundingBox();
    const { min, max } = geometry.boundingBox;
    const offset = new THREE.Vector2(0 - min.x, 0 - min.y);
    const range = new THREE.Vector2(max.x - min.x, max.y - min.y);
    const uvAttribute = geometry.attributes.uv;
    for (let i = 0; i < uvAttribute.count; i++) {
      uvAttribute.setXY(i, (uvAttribute.getX(i) + offset.x) / range.x, (uvAttribute.getY(i) + offset.y) / range.y);
    }

    return geometry;
  };

  const geometry = useMemo(() => createRoundedPlaneGeometry(), [width, height, radius]);

  return (
    <group {...props}>
      <mesh receiveShadow castShadow onClick={handlePlayVideo} geometry={geometry}>
        <meshStandardMaterial side={THREE.DoubleSide}>
          <videoTexture
            ref={videoTextureRef}
            attach="map"
            args={[video]}
            wrapS={THREE.ClampToEdgeWrapping}
            wrapT={THREE.ClampToEdgeWrapping}
            format={THREE.RGBAFormat}
            minFilter={THREE.LinearFilter}
            magFilter={THREE.LinearFilter}
          />
          <videoTexture
            attach="emissiveMap"
            args={[video]}
            wrapS={THREE.ClampToEdgeWrapping}
            wrapT={THREE.ClampToEdgeWrapping}
            format={THREE.RGBAFormat}
            minFilter={THREE.LinearFilter}
            magFilter={THREE.LinearFilter}
          />
        </meshStandardMaterial>
      </mesh>
      <PausePlayButton 
        video={video} 
        positionOffset={[5, 2.7, 0.3]} 
        imgPathPlay="images/play button.svg" 
        imgPathPause="images/pause button.svg" 
        scale={[0.3, -0.3, 0.1]} 
      />
    </group>
  );
};

export default CustomRoundedPlane;
