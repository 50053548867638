// HeadAppSection.js
import React, { Suspense, lazy } from 'react';

const HeadApp = lazy(() => import('../HeadApp'));

const HeadAppSection = () => (
  <div className="headAppContainer">
    <Suspense fallback="">
      <HeadApp />
    </Suspense>
    <p className="scrollToExplore">
      SCROLL TO EXPLORE
    </p>
  </div>
);

export default HeadAppSection;
