import React, { useRef } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import * as THREE from 'three';

const FocusButton = ({ positionOffset = [0, 1, 0], imgPath = "", scale = [1, 1, 1], onFocus, onSetTarget, controlsRef }) => {
    const texture = useLoader(THREE.TextureLoader, imgPath);
    const buttonRef = useRef();

    const handleClick = (e) => {
        e.stopPropagation();
        const targetPosition = new THREE.Vector3().setFromMatrixPosition(buttonRef.current.matrixWorld);
        onSetTarget(targetPosition); // Pass the new target position
        onFocus(true);
    };

    useFrame(({ camera }) => {
        if (buttonRef.current) {
            buttonRef.current.lookAt(camera.position);
        }
    });

    return (
        <mesh 
            ref={buttonRef}
            position={positionOffset}
            onClick={handleClick}
            scale={scale}
        >
            <planeGeometry args={[1, 1]} />
            <meshBasicMaterial 
                map={texture}
                transparent={true}
            />
        </mesh>
    );
}

export default FocusButton;
