// CloudSectionContainer.js
import React from 'react';
import CloudScroll from '../CloudScroll';

const CloudSectionContainer = ({ cloudSectionRef }) => (
  <div id="CloudSection" ref={cloudSectionRef}>
    <CloudScroll />
  </div>
);

export default CloudSectionContainer;
