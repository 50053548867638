// TitleSection.js
import React from 'react';
import SunBallSection from './SunBallSection';

const TitleSection = () => (
  <section className="container">
    <div className="title">
      <h2 id="titleh2">
        Bridging Realities: Immersive 3D Platform for All
      </h2>
    </div>
    <div className="bodyCopyVideoBall">
      <p>
        Memotics Design Studio: a Service-based Tech Enabler to integrate advanced technologies and create immersive experiences for companies.
      </p>
      <p>
        We are architects, digital artists, and entrepreneurs that empowers you to break through the noise, access world-class tools, and captivate global audiences, fostering success in your industry.
      </p>
      <button href="https://memotics.io/AboutUs/" className="aboutUsBtn"><a href="https://memotics.io/AboutUs/">About Us</a></button>
    </div>
    <SunBallSection />
  </section>
);

export default TitleSection;
