// SunBallSection.js
import React from 'react';
import SunBall from "../SunBall";

const SunBallSection = () => (
  <div className="videoBallEffect">
    <section className="Sunball">
      <SunBall />
    </section>
    <div className="text-overlay">video we like by CD Kota Iguchi</div>
  </div>
);

export default SunBallSection;
