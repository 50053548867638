// DifferentByDesignSection.js
import React from 'react';

const DifferentByDesignSection = () => (
  <section className="container">
    <div className="section3">
      <h2>
        Different by Design. Built for creatives by Creatives.
      </h2>
    </div>
  </section>
);

export default DifferentByDesignSection;
